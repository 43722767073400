import { Box, FormGroup, Grid, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  UilCalendarAlt,
  UilLayerGroupSlash,
  UilAngleDown,
} from "@iconscout/react-unicons";
import { RButton, RChip } from "src/components";
import { StyledMenu } from "src/layouts/SearchBarComponent";
import { typeVar } from "src/components/RButton";
import GridItem from "src/components/GridItem";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { isLoading, selectUser } from "src/store/user/userSlice";
import {
  getAllCampaigns,
  getAssignedCampaigns,
  resetCampaigns,
  selectUserCampaigns,
} from "src/store/campaignsData/campaignDataSlice";
import { ACTIVE, ADMIN, Campaign, PAUSED, PENDING, SUPER_USER } from "src/types/types";
import CampaignCard from "src/components/CampaignCard";
import CustomContainer from "src/components/CustomContainer";
import LoaderTable from "src/components/LoaderTable";
import CampaignPreview from "src/components/CampaignPreview";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AllCampaigns = () => {
  const [value, setValue] = useState(0);
  const [isCampaignPreview,setIsCampaignPreview]= useState(false);
  const [campaign,setCampaign]=useState<Campaign>()

  const [anchorElFilter, setAnchorElFilter] =
    useState<null | HTMLElement>(null);

  const openFilter = Boolean(anchorElFilter);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const loading = useAppSelector(isLoading);
  const campaigns = useAppSelector(selectUserCampaigns);

  const handleSetPreview=(obj:Campaign)=>{
    setCampaign(obj)
    setIsCampaignPreview(true)
  }

  useEffect(() => {
    if (user?.userRole === SUPER_USER || user?.userRole === ADMIN) {
      dispatch(getAllCampaigns(null));
    } else {
      dispatch(getAssignedCampaigns(user?.id as string));
    }

    return () => {
      dispatch(resetCampaigns());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoaderTable />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        display: "flex",
      }}
    >
      <CustomContainer>
    { isCampaignPreview ? <CampaignPreview campaign={campaign} setIsCampaignPreview={setIsCampaignPreview}/>:
        <> <Box
          sx={{
            display: "flex",
            marginTop: 5,
            justifyContent: "space-between",
          }}
        >
          <Box marginRight={7} sx={{ display: "flex" }}>
            <Typography variant="h5Bold" sx={{ marginRight: "25px" }}>
              All Campaigns
            </Typography>
          </Box>
          {value === 0 ? (
            <Box sx={{ display: "flex", marginTop: 1 }}>
              <Box
                display="flex"
                sx={{
                  padding: 0.5,
                  background: "rgba(104, 222, 110, 0.2)",
                  borderRadius: "13px",
                  width: "70px",
                  height: "20px",
                  marginRight: 1,
                }}
              >
                <img
                  src="/static/images/app/greenDot.svg"
                  alt="green"
                  style={{ width: 5, marginRight: 7 }}
                />
                <Typography variant="smallerBold" color={"#68DE6E"}>
                  {campaigns.filter((c) => c.status === ACTIVE).length} Active
                </Typography>
              </Box>
              <Box
                display="flex"
                sx={{
                  padding: 0.5,
                  background: "rgba(250, 165, 26, 0.1)",
                  borderRadius: "13px",
                  width: "85px",
                  height: "20px",
                  marginRight: 1,
                }}
              >
                <img
                  src="/static/images/app/orangedot.svg"
                  alt="green"
                  style={{ width: 5, marginRight: 7 }}
                />
                <Typography variant="smallerBold" color={" #FFBA17"}>
                  {/* TODO: We need to review campaign states, so far we can only view active and past, no inactive (possibly past?) or pending tabs */}
                  {campaigns.filter((c) => c.status === PENDING).length}  Pending
                </Typography>
              </Box>
              <Box
                display="flex"
                sx={{
                  padding: 0.5,
                  background: "rgba(249, 44, 44, 0.1)",
                  borderRadius: "13px",
                  width: "85px",
                  height: "20px",
                  marginRight: 1,
                }}
              >
                <img
                  src="/static/images/app/red.svg"
                  alt="green"
                  style={{ width: 5, marginRight: 7 }}
                />
                <Typography variant="smallerBold" color={"#F92C2C"}>
                  {campaigns.filter((c) => c.status === PAUSED).length} Paused
                </Typography>
              </Box>
            </Box>
          ) : null}
          <Box></Box>
          {/* <Box sx={{ position: "relative", right: 0, top: 45 }}>
            <RButton
              name="Sort"
              typeVar={typeVar.OutlinedBlack}
              endIcon={<UilAngleDown />}
              onEvent={() => handleFilterClick}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElFilter}
              open={openFilter}
              onClose={handleCloseFilter}
            >
              <MenuItem disableRipple>
                <FormGroup>
                  <MenuItem onClick={handleCloseFilter} disableRipple>
                    Status
                  </MenuItem>
                  <MenuItem onClick={handleCloseFilter} disableRipple>
                    Name
                  </MenuItem>

                  <MenuItem onClick={handleCloseFilter} disableRipple>
                    A to Z
                  </MenuItem>
                  <MenuItem onClick={handleCloseFilter} disableRipple>
                    Z to A
                  </MenuItem>
                </FormGroup>
              </MenuItem>
            </StyledMenu>
          </Box> */}
        </Box>
        <Box sx={{ width: "100%", marginTop: 2 }}>
          <Box sx={{ borderBottom: 2.5, borderColor: "#F3F3F3" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiButtonBase-root": {
                  color: "grey #important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                  height: 3,
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "2px solid #000",
                },

                "& .MuiTab-root.Mui-selected": {
                  color: "#000",
                },
              }}
            >
              <Tab label="Current" {...a11yProps(0)} />
              {/* <Tab label="Past" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ flexGrow: 1, marginTop: 1 }}>
              <Grid container spacing={2}>
                {campaigns
                  // .filter((c) => !c.past)
                  .map((obj: Campaign, index: number) => {
                    return (
                      <Grid item sm={6} xs={12} md={4} lg={3} key={index}  onClick={()=>handleSetPreview(obj)}>
                        <GridItem color="#fff">
                          <CampaignCard
                            socialMediaLabel={obj.name}
                            isMonitor={true}
                            campaignDuration={obj.duration}
                            campaignHeader={obj.title}
                            campaignSubHeader={obj.title}
                            campaignTypeId={obj.campaignTypeId}
                            status={obj.status}
                          />
                        </GridItem>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <Box sx={{ flexGrow: 1, marginTop: 1 }}>
              <Grid container spacing={2}>
                {campaigns
                  .filter((c) => c.past)
                  .map((obj: Campaign, index: number) => {
                    return (
                      <Grid item sm={6} xs={12}  md={4} lg={3} key={index}>
                        <GridItem color={"#000"}>
                          <Box padding={1}>
                            <Box
                              sx={{
                                padding: 0.5,
                                display: "flex",
                                backgroundColor: "rgba(249, 44, 44, 0.2)",
                                width: 90,
                                borderRadius: "19px",
                                height: 27,
                                cursor:"pointer"
                              }}
                            >
                              <UilLayerGroupSlash
                                size={15}
                                color={"rgba(249, 44, 44, 1)"}
                                style={{ marginTop: "2px" }}
                              />
                              <Typography
                                sx={{
                                  color: "rgba(249, 44, 44, 1)",
                                  fontSize: "16px",
                                  marginLeft: 1,
                                  fontWeight: 700,
                                  marginTop: -0.2,
                                }}
                              >
                                Past
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                paddingLeft: 1,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="h6Bold"
                                sx={{ color: "#fff" }}
                              >
                                {obj.title}
                              </Typography>
                              <Typography
                                variant="small"
                                sx={{ color: "#9B9B9B", marginY: 1 }}
                              >
                                {obj.description}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  backgroundColor: "rgba(255, 255, 255, 0.15)",
                                  padding: 0.5,
                                  borderRadius: "8px",
                                  marginY: 1,
                                  color: "#fff",
                                }}
                              >
                                <UilCalendarAlt
                                  size={15}
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "10px",
                                  }}
                                />
                                <Typography sx={{ fontSize: "15px" }}>
                                  {calculateDate(obj.duration)}
                                </Typography>
                              </Box>

                              <Box>
                                <RChip
                                  label="Instagram"
                                  sx={{
                                    fontWeight: 700,
                                    height: 25,
                                    padding: 1,
                                    backgroundColor: "#FFF",
                                    color: "#000",
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </GridItem>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </TabPanel> */}
        </Box></>
       }
      </CustomContainer>
    </Box>
  );
};

export default AllCampaigns;
