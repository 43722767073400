import { Box, Typography } from "@mui/material";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { RChip } from "src/components";
import Status, { statusEnum } from "../../components/Status";
import React, { PropsWithChildren } from "react";
import moment from "moment";
import {
  DISCOVERYMULTIASSETAD,
  RESPONSIVEDISPLAYAD,
  RESPONSIVESEARCHAD,
  VIDEORESPONSIVEAD,
} from "src/types/types";

type CampaignCardProps = {
  campaignHeader: string;
  campaignSubHeader: string;
  campaignDuration: string;
  socialMediaLabel: string;
  status: string;
  isMonitor: boolean;
  campaignTypeId: number;
};

const CampaignCard: React.FC<PropsWithChildren<CampaignCardProps>> = ({
  campaignDuration,
  campaignHeader,
  campaignSubHeader,
  status,
  socialMediaLabel,
  isMonitor,
  campaignTypeId,
}) => {
  const getCampaignType = (campaignTypeId: number) => {
    switch (campaignTypeId) {
      case RESPONSIVEDISPLAYAD:
        return "Display";
      case RESPONSIVESEARCHAD:
        return "Search";
      case VIDEORESPONSIVEAD:
        return "Video";
      case DISCOVERYMULTIASSETAD:
        return "Discovery";
      default:
        return "";
    }
  };
 
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingX: "10px",
          paddingTop: "17px",
          cursor:"pointer"
        }}
      >
        <Status name={status} />
        {/* {isMonitor && (
          <Box display="flex" sx={{ cursor:"pointer"}}>
            <Typography sx={{ marginRight: "5px" }}>Monitor</Typography>
            <img src="/static/images/app/monitor.svg" alt="monitor" />
          </Box>
        )} */}
      </Box>
      <Box
        sx={{
          paddingX: "20px",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "column",
          cursor:"pointer"
        }}
      >
        <Typography variant="h6Bold">
          {campaignHeader?.substring(0, 16)}
          {campaignHeader?.length >= 16 && "..."}
        </Typography>
        <Typography variant="small" sx={{ color: "#9B9B9B", marginY: 1 }}>
          {campaignSubHeader?.substring(0, 19)}
          {campaignSubHeader?.length >= 19 && "..."}
        </Typography>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F3F3F3",
            padding: 0.5,
            borderRadius: "8px",
            marginY: 1,
          }}
        >
          <UilCalendarAlt
            size={15}
            style={{ marginTop: "3px", marginRight: "1px" }}
          />
          <Typography sx={{ fontSize: "14px" }}>
            {campaignDuration}
          </Typography>
        </Box>
        <Box>
          <RChip
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              fontWeight: 700,
              height: 25,
              padding: 1,
              marginRight: 2,
              cursor:"pointer"
            }}
            label={socialMediaLabel}
          />
          {campaignTypeId !== 1 && (
            <RChip
              label={getCampaignType(campaignTypeId)}
              sx={{
                color: "#fff",
                backgroundColor: "#000",
                fontWeight: 700,
                height: 25,
                padding: 1,
                cursor:"pointer"
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CampaignCard;
