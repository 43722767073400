import axios, { AxiosError, AxiosResponse } from "axios";
import { error } from "console";
import { toast } from "react-toastify";
import pages from "src/router/routes";

export interface Resource {
  id: string;
}

export interface HttpClient<ResourceType extends Resource> {
  getAll: (
    authToken: string,
    id?: string,
    type?: string,
    clientId?: string
  ) => Promise<AxiosResponse<ResourceType[]>>;
  getOne: (
    id: string,
    authToken: string,
    userId?: string
  ) => Promise<AxiosResponse<ResourceType>>;
  create: (
    data: Omit<ResourceType, "id">,
    authToken: string
  ) => Promise<AxiosResponse<ResourceType>>;
  update: (
    data: Partial<Omit<ResourceType, "id">>,
    authToken: string
  ) => Promise<AxiosResponse<ResourceType>>;
  updateAll: (
    data: ResourceType[],
    authToken: string
  ) => Promise<AxiosResponse[]>;
  delete: (id: string, authToken: string) => Promise<AxiosResponse<void>>;
}

export const CreateHttpClient = <ResourceType extends Resource>(
  resource: string
): HttpClient<ResourceType> => {
  let baseUrl = `https://localhost:7153/api/1/${resource}`;

  if (process.env.REACT_APP_ENV === "Production") {
    baseUrl = `https://marketing-platform-prod.azurewebsites.net/api/1/${resource}`;
  } else if (process.env.REACT_APP_ENV === "Staging") {
    baseUrl = `https://marketing-platform-api.azurewebsites.net/api/1/${resource}`;
  }

  const handleUnauthorized = (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = pages.auth.login.path;
    }
    throw error;
  };

  const handleIntegrationErrors = (error: AxiosError) => {
    if (error.response && error.response.status === 400) {
      const responseData = error.response.data as Array<{ data: any, hint: any, message: any, recommedations: any, statusCode: number }> | { data: any, hint: any, message: any, recommedations: any, statusCode: number };
      if (Array.isArray(responseData)) {
        responseData.forEach((response) => {
          toast.warning(response.message);
          if (response.hint) {
            toast.warning(response.hint);
          }
        });
      } else {
        toast.warning(responseData.message);
        if (responseData.hint) {
          toast.warning(responseData.hint);
        }
      }
    }
    throw error;
  };



  // TODO: This needs rework
  // const handleOtherError = (
  //   error: AxiosError,
  //   messsage: string,
  //   type?: string
  // ) => {
  //   if (messsage.toLowerCase().includes("campaign")) {
  //     toast.error(
  //       `"Error fetching Campaign(s), please contact support or try again later`
  //     );
  //   } else if (messsage.toLowerCase().includes("user") && type === "create") {
  //     toast.error(
  //       "Could not create, please contact support or try again later "
  //     );
  //   } else if (messsage.toLowerCase().includes("user") && type === "getOne") {
  //     toast.error(
  //       "Could not get user(s), please contact support or try again later "
  //     );
  //   } else if (type === "update") {
  //     toast.error(
  //       "Could not update, please contact support or try again later "
  //     );
  //   } else if (type === "updateAll") {
  //     toast.error(
  //       "Could not update all, please contact support or try again later "
  //     );
  //   } else if (type === "deleteResource") {
  //     toast.error(
  //       "Could not delete, please contact support or try again later "
  //     );
  //   }
  //   throw error;
  // };

  const getAll = async (
    authToken: string,
    id?: string,
    type?: string,
    clientId?: string
  ): Promise<AxiosResponse<ResourceType[]>> => {

    return await axios
      .get(
        `${baseUrl}?userId=${id ?? ""}&type=${type ?? ""}&clientId=${clientId ?? ""
        }`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      )
      .catch(handleUnauthorized)
    // .catch((err) =>
    //   handleOtherError(
    //     err,
    //     `${baseUrl}?userId=${id ?? ""}&type=${type ?? ""}&clientId=${
    //       clientId ?? ""
    //     }`
    //   )
    // );
  };

  const getOne = async (
    id: string,
    authToken: string,
    userId?: string
  ): Promise<AxiosResponse<ResourceType>> => {
    return await axios
      .get(`${baseUrl}/${id}?userId=${userId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .catch(handleUnauthorized)
    // .catch((errors) => handleOtherError(errors, baseUrl, "getOne"));
  };

  const create = async (
    data: Omit<ResourceType, "id">,
    authToken: string
  ): Promise<AxiosResponse<ResourceType>> => {
    return await axios
      .post(baseUrl, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .catch(handleUnauthorized)
      .catch(handleIntegrationErrors);
  };

  const update = async (
    data: Partial<Omit<ResourceType, "id">>,
    authToken: string
  ): Promise<AxiosResponse<ResourceType>> => {
    return await axios
      .put(`${baseUrl}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .catch(handleUnauthorized)
      .catch(handleIntegrationErrors);
  };

  const updateAll = async (
    data: ResourceType[],
    authToken: string
  ): Promise<AxiosResponse[]> => {
    const updatePromises = data.map((obj) =>
      axios.put(`${baseUrl}`, obj, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
    );

    return await Promise.all(updatePromises)
      .catch(handleUnauthorized)
      .catch(handleIntegrationErrors);
  };

  const deleteResource = async (
    id: string,
    authToken: string
  ): Promise<AxiosResponse<void>> => {
    return await axios
      .delete(`${baseUrl}/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .catch(handleUnauthorized)
    // .catch((err) => handleOtherError(err, baseUrl, "deleteResource"));
  };

  return {
    getAll,
    getOne,
    create,
    update,
    updateAll,
    delete: deleteResource,
  };
};
