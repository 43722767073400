import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import themeSlice from "./theme/themeSlice";
import userSlice from "./user/userSlice";
import campaignSlice from "./campaignsData/campaignDataSlice";
import userDataSlice from "./usersData/userDataSlice";
import clientSlice from "./client/clientSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const combinedReducer = combineReducers({
  theme: themeSlice,
  user: userSlice,
  campaign: campaignSlice,
  userData: userDataSlice,
  client: clientSlice,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "user/resetUser") {
    // Reset the Redux store
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
