const pages = {
  revx: {
    root: "revx",
    client: {
      name: "clients",
      path: "/revx/clients",
    },
    revxusers: {
      name: "revx-users",
      path: "/revx/revx-users",
    },
    profile: {
      name: "profile",
      path: "/revx/profile",
    },
    clientProfile: {
      name: "client-profile",
      path: "/revx/client-profile",
    },
    userProfile: {
      name: "user-profile",
      path: "/revx/user-profile",
    },
    assignCampaigns: {
      name: "assign-campaigns",
      path: "/revx/assign-campaigns",
    },
    allCampaigns: {
      name: "all-campaigns",
      path: "/revx/all-campaigns",
    },
    viewAllClientCampaigns: {
      name: "view-allclient-campaigns",
      path: "/revx/view-allclient-campaigns",
    },
    pastCampaigns: {
      name: "past-campaigns",
      path: "/revx/past-campaigns",
    },
    currentCampaigns: {
      name: "current-campaigns",
      path: "/revx/current-campaigns",
    },
    startANewCampaign: {
      name: "start-a-new-campaign",
      path: "/revx/start-a-new-campaign",
    },
    facebookWizard: {
      name: "facebook-wizard",
      path: "/revx/facebook-wizard",
    },
    googleWizard: {
      name: "google-wizard",
      path: "/revx/google-wizard",
    },
    facebookWizardEdit: {
      name: "facebook-wizard/:id",
      path: "/revx/facebook-wizard/:id",
    },
    googleWizardEdit: {
      name: "google-wizard/:id",
      path: "/revx/google-wizard/:id",
    },
    inbox: {
      name: "inbox",
      path: "/revx/inbox",
    },
  },
  status: {
    root: "status",
    statusComingSoon: {
      name: "coming-soon",
      path: "/status/coming-soon",
    },
    statusMaintenance: {
      name: "maintenance",
      path: "/status/maintenance",
    },
    status404: {
      name: "404",
      path: "/status/404",
    },
    status500: {
      name: "500",
      path: "/status/500",
    },
  },
  auth: {
    root: "auth",
    login: {
      name: "login",
      path: "/auth/login",
    },
    signup: {
      name: "signup",
      path: "/auth/signup",
    },
    forgotPassword: {
      name: "forgot-password",
      path: "/auth/forgot-password",
    },
    checkEmail: {
      name: "check-email",
      path: "/auth/check-email",
    },
    validateEmail: {
      name: "validate-email/:id",
      path: "/auth/validate-email/:id",
    },
    setNewPassword: {
      name: "set-new-password",
      path: "/auth/set-new-password",
    },
    passwordReset: {
      name: "password-reset",
      path: "/auth/password-reset",
    },
  },
  input: {
    name: "input",
    path: "/sidebar/input",
  },
};

export default pages;
