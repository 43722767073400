import {
  Box,
  IconButton,
  Button,
  Typography,
  Toolbar,
  Avatar,
} from "@mui/material";
import {
  UilUserArrows,
  UilUsersAlt,
  UilLayers,
  UilLayersSlash,
} from "@iconscout/react-unicons";
import { NavLink as RouterLink } from "react-router-dom";
import HeaderButtons from "./Buttons";
import * as React from "react";
import { ADMIN, CAMPAIGN_MANAGER, CLIENT, SUPER_USER } from "src/types/types";

import pages from "src/router/routes";
import { useAppSelector } from "src/hooks/hooks";
import { selectUser } from "src/store/user/userSlice";
import { IUser } from "src/store/user/userSlice.contracts";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from "src/firebase";

const Header = () => {
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const [notifcation, setnotifcation] = React.useState<any>(null);
  useEffect(() => {
    let unsubscribe: any;
    if (user?.firebaseId) {
      const notifcationsRef = firebase
      .firestore()
      .collection('notifcations')
      .where('firebaseUserId', '==', user?.firebaseId);
      unsubscribe = notifcationsRef.where('isRead', '==', false).onSnapshot((snapshot: any) => {
        setnotifcation(snapshot.size);
      });
    } else {
      console.log('anon_user')
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  const headerSwitcher = (user: IUser | null) => {
    const role = user?.userRole;
    switch (role) {
      case SUPER_USER:
        return (
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              <Box display="flex">
                <IconButton sx={{ p: 0, mr: 5 }}>
                  <Avatar alt="Logo" src="/static/images/app/headerLogo.svg" />
                </IconButton>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.client.path}
                    startIcon={<UilUserArrows />}
                    sx={{
                      background:
                        location.pathname === pages.revx.client.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      Clients
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.revxusers.path}
                    startIcon={<UilUsersAlt />}
                    sx={{
                      background:
                        location.pathname === pages.revx.revxusers.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      RevX Users
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.allCampaigns.path}
                    startIcon={<UilLayers />}
                    sx={{
                      background:
                        location.pathname === pages.revx.allCampaigns.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      Campaigns
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <HeaderButtons notifcation={notifcation} />
              </Box>
            </Box>
          </Toolbar>
        );
      case ADMIN:
        return (
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              <Box display="flex">
                <IconButton sx={{ p: 0, mr: 5 }}>
                  <Avatar alt="Logo" src="/static/images/app/headerLogo.svg" />
                </IconButton>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.client.path}
                    startIcon={<UilUserArrows />}
                    sx={{
                      background:
                        location.pathname === pages.revx.client.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      Clients
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.allCampaigns.name}
                    startIcon={<UilLayers />}
                    sx={{
                      background:
                        location.pathname === pages.revx.allCampaigns.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      All Campaigns
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <HeaderButtons notifcation={notifcation} />
              </Box>
            </Box>
          </Toolbar>
        );

      case CAMPAIGN_MANAGER:
        return (
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              <Box display="flex">
                <IconButton sx={{ p: 0, mr: 5 }}>
                  <Avatar alt="Logo" src="/static/images/app/headerLogo.svg" />
                </IconButton>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.allCampaigns.name}
                    startIcon={<UilLayers />}
                    sx={{
                      background:
                        location.pathname === pages.revx.allCampaigns.name
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      All Campaigns
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <HeaderButtons notifcation={notifcation} />
              </Box>
            </Box>
          </Toolbar>
        );

      case CLIENT:
        return (
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              <Box display="flex">
                <IconButton sx={{ p: 0, mr: 5 }}>
                  <Avatar alt="Logo" src="/static/images/app/headerLogo.svg" />
                </IconButton>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.currentCampaigns.path}
                    startIcon={<UilLayers />}
                    sx={{
                      background:
                        location.pathname === pages.revx.currentCampaigns.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      Currrent Campaigns
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.pastCampaigns.path}
                    startIcon={<UilLayersSlash />}
                    sx={{
                      background:
                        location.pathname === pages.revx.pastCampaigns.path
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      Past Campaigns
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <HeaderButtons notifcation={notifcation} />
              </Box>
            </Box>
          </Toolbar>
        );
      default:
        return (
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              <Box display="flex">
                <IconButton sx={{ p: 0, mr: 5 }}>
                  <Avatar alt="Logo" src="/static/images/app/headerLogo.svg" />
                </IconButton>
                <Box>
                  <Button
                    component={RouterLink}
                    to={pages.revx.allCampaigns.name}
                    startIcon={<UilLayers />}
                    sx={{
                      background:
                        location.pathname === pages.revx.allCampaigns.name
                          ? "rgba(255, 255, 255, 0.15)"
                          : null,
                    }}
                  >
                    <Typography variant="small" color="#fff">
                      All Campaigns
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <HeaderButtons notifcation={notifcation} />
              </Box>
            </Box>
          </Toolbar>
        );
    }
  };

  return headerSwitcher(user);
};
export default Header;
