import { Box } from "@mui/material";
import Inbox from "./Inbox";
import Notifcation from "./Notification";
import User from "./User";

interface IHeaderButtons {
  notifcation: number;
}

function HeaderButtons({ notifcation }: IHeaderButtons) {
  return (
    <Box sx={{ display: "flex", mr: 1 }}>
      <Notifcation notifcation={notifcation}/>
      <Inbox />
      <User />
    </Box>
  );
}

export default HeaderButtons;
