import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { StyledMenu } from "src/layouts/SearchBarComponent";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import PastGroupCampaings from "./pastGroupCampaigns";
import CustomContainer from "src/components/CustomContainer";
import EmptyState from "src/components/EmptyStates";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getAssignedCampaignsClient, resetCampaigns, selectUserCampaigns } from "src/store/campaignsData/campaignDataSlice";
import { selectUser } from "src/store/user/userSlice";
import { getClient, getUserClient } from "src/store/client/clientSlice";
import { ACTIVE, Campaign, PAUSED } from "src/types/types";
import GridItem from "src/components/GridItem";
import CampaignCard from "src/components/CampaignCard";
import _ from "lodash";
import CampaignPreview from "src/components/CampaignPreview";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-10%, -18%)",
//   width: 400,

//   p: 4,
// };

const PastCampaigns = () => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] =useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [isCampaignPreview, setIsCampaignPreview] = useState(false);
  const [campaign, setCampaign] = useState<Campaign>();

  const handleSetPreview = (obj: Campaign) => {
    setCampaign(obj);
    setIsCampaignPreview(true);
  };

  // const handleOpen = () => setOpen(true);

  // const handleClose = () => setOpen(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const campaigns = useAppSelector(selectUserCampaigns);
  const client = useAppSelector(getClient);


  useEffect(() => {
    dispatch(resetCampaigns())
    dispatch(getUserClient(user?.id as string));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (client) {
      dispatch(getAssignedCampaignsClient(client.id as string));

    }


    return () => {
      dispatch(resetCampaigns());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);


  const opened = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ background: "#000", paddingTop: 2 }}>
        <CustomContainer >
          <Box sx={{ borderBottom: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiButtonBase-root": {
                  color: "grey #important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                  height: 3,
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "2px solid #fff",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#fff",
                },

              }}
            >
              {/* <Tab label="Single" {...a11yProps(0)} />
            <Tab label="Group" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
        </CustomContainer>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            marginTop: 2,
          }}
        >
          <CustomContainer>
            {
                isCampaignPreview ? (
                  <CampaignPreview
                    campaign={campaign}
                    setIsCampaignPreview={setIsCampaignPreview}
                  />
                ):<><Box marginY={1}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      marginRight: 10,
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                      Past Campaigns
                    </Typography>
                    <Typography sx={{ color: "#9B9B9B", fontSize: "14px" }}>
                      A list of all the campaigns who have been completed
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: 1 }}>
                    {/* <Typography
                      sx={{
                        marginTop: 0.7,
                        marginRight: 1,
                        color: "#727272",
                        fontWeight: 500,
                      }}
                    >
                      Duration
                    </Typography> */}
                    {/* <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          <UilCalendarAlt />
                        </InputAdornment>
                      }
                      onClick={handleOpen}
                      sx={{
                        height: 35,
                        width: 300,
                        marginRight: 3,
                        borderRadius: "13px",
                        cursor: "pointer",
                        color: "#000 !important",
                        "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined":
                          {
                            color: "#000 !important",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E5E4E2",
                          color: "#000 !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#000",
                          color: "#000",
                        },
                        "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#000",
                          color: "#000",
                        },
  
                        "&.MuiInputbase-input": {
                          color: "#000",
                        },
                      }}
                      value={
                        state[0].endDate === null
                          ? "Select a time"
                          : `${moment(state[0].startDate).format(
                              "MMM Do YY"
                            )}-${moment(state[0].endDate).format("MMM Do YY")}`
                      }
                      placeholder={"Select a date"}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    /> */}
                    {/* <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <DateRange
                          editableDateInputs={false}
                          //@ts-ignore
                          onChange={(item) => setState([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                        />
                      </Box>
                    </Modal> */}
                    {/* <RButton
                      name="Sort"
                      typeVar={typeVar.OutlinedBlack}
                      endIcon={<UilAngleDown />}
                      //@ts-ignore
                      onEvent={handleClick}
                      height={36}
                    /> */}
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={opened}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleCloseMenu} disableRipple>
                        Newest
                      </MenuItem>
                      <MenuItem onClick={handleCloseMenu} disableRipple>
                        Oldest
                      </MenuItem>
  
                      <MenuItem onClick={handleCloseMenu} disableRipple>
                        A to Z
                      </MenuItem>
                      <MenuItem onClick={handleCloseMenu} disableRipple>
                        Z to A
                      </MenuItem>
                    </StyledMenu>
                  </Box>
                </Box>
              </Box>
              <Divider />
              {/* {active ? <Active /> : <Pending />} */}
              {/* <Box sx={{ display: "flex", marginTop: 2 }}>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  startAdornment={
                    <InputAdornment position="end">
                      <UilSearch />
                    </InputAdornment>
                  }
                  sx={{
                    height: 35,
                    width: 1230,
                    marginRight: 2,
                    borderRadius: "13px",
                    color: "#000 !important",
                    "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined":
                      {
                        color: "#000 !important",
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E5E4E2",
                      color: "#000 !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#000",
                      color: "#000",
                    },
                    "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#000",
                      color: "#000",
                    },
  
                    "&.MuiInputbase-input": {
                      color: "#000",
                    },
                  }}
                  placeholder={"Search for a past campaign"}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
                <RButton
                  name="Search"
                  height={35}
                  typeVar={typeVar.OutlinedBlack}
                />
              </Box> */}
              {_.isEmpty(campaigns.filter(c => c.status === PAUSED)) ? (
                <EmptyState
                  heading="No past campaign yet"
                  subHeading="Past campaigns get added here when they have been completed"
                  imageUrl="/static/images/app/pastEmpty.svg"
                />
              ) : (
                <Box sx={{ flexGrow: 1, marginTop: 1 }}>
                  <Grid container spacing={2}>
                    {campaigns.filter(c => c.status === PAUSED)
                      .map((obj: Campaign, index: number) => {
                        return (
                          <Grid item sm={6} xs={12} md={4} lg={3} key={index} onClick={() => handleSetPreview(obj)}>
                            <GridItem color="#fff">
                              <CampaignCard
                                socialMediaLabel={obj.name}
                                isMonitor={true}
                                campaignDuration={obj.duration}
                                campaignHeader={obj.title}
                                campaignSubHeader={obj.title}
                                campaignTypeId={obj.campaignTypeId}
                                status={obj.status}
                              />
                            </GridItem>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              )}</>
            }
            
          </CustomContainer>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomContainer>
            <Box marginY={3}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    marginRight: 5,
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                    Campaign Groups
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "14px", color: "#9B9B9B" }}
                  >
                    A list of all the campaigns who have been completed
                  </Typography>
                </Box>

                {/* <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      marginTop: 0.7,
                      marginRight: 1,
                      color: "#727272",
                      fontWeight: 500,
                    }}
                  >
                    Duration
                  </Typography>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">
                        <UilCalendarAlt />
                      </InputAdornment>
                    }
                    onClick={handleOpen}
                    sx={{
                      height: 35,
                      marginRight: 3,
                      borderRadius: "13px",
                      cursor: "pointer",
                      color: "#000 !important",
                      "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined":
                        {
                          color: "#000 !important",
                        },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E4E2",
                        color: "#000 !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        color: "#000",
                      },
                      "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        color: "#000",
                      },

                      "&.MuiInputbase-input": {
                        color: "#000",
                      },
                    }}
                    value={
                      state[0].endDate === null
                        ? "Select a time"
                        : `${moment(state[0].startDate).format(
                            "MMM Do YY"
                          )}-${moment(state[0].endDate).format("MMM Do YY")}`
                    }
                    placeholder={"Select a date"}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <DateRange
                        editableDateInputs={false}
                        //@ts-ignore
                        onChange={(item) => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                      />
                    </Box>
                  </Modal>
                  <RButton
                    name="Sort"
                    typeVar={typeVar.OutlinedBlack}
                    endIcon={<UilAngleDown />}
                    //@ts-ignore
                    onEvent={handleClick}
                    height={36}
                  />
                </Box> */}

                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={opened}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleCloseMenu} disableRipple>
                    Newest
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu} disableRipple>
                    Oldest
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} disableRipple>
                    A to Z
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu} disableRipple>
                    Z to A
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Box>
            <Divider sx={{ backgroundColor: "#F3F3F3" }} />
            <PastGroupCampaings />
          </CustomContainer>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default PastCampaigns;
