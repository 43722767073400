import { Button, ButtonBaseProps } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import {
  OUTLINEDBLACK,
  CONTAINEDBLACK,
  CONTAINED,
  OUTLINEDWHITE,
} from "src/types/types";
import CircularIndeterminate from "../Spinner";
export enum typeVar {
  Contained = "contained",
  ContainedBlack = "containedBlack",
  OutlinedBlack = "outlinedBlack",
  OutlinedWhite = "outlinedWhite",
  General = "general",
}
interface RButtonProps extends ButtonBaseProps {
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  typeVar: typeVar;
  width?: number;
  height?: number;
  name: string;
  isSubmitting?: boolean;
  blackButton?: boolean;
  onEvent?: () => void;
  marginTop?: number;
}

const RButton: React.FC<PropsWithChildren<RButtonProps>> = ({
  typeVar,
  width,
  endIcon,
  startIcon,
  name,
  height,
  isSubmitting,
  blackButton,
  onEvent,
  marginTop,
  sx,
}) => {
  return (
    <Button
      endIcon={endIcon}
      type="submit"
      onClick={onEvent}
      disabled={isSubmitting}
      sx={
        typeVar === CONTAINED
          ? {
              width: `${width}px`,
              height: `${height}px`,
              borderRadius: "30px",
              backgroundImage:
                "linear-gradient(90deg, rgba(254,189,47,1) 0%, rgba(253,29,29,1) 49%, rgba(23,40,155,1) 100%)",
              color: "#fff",
              marginTop: 1.5,
              marginRight: 2,
            }
          : typeVar === CONTAINEDBLACK
          ? {
              width: `${width}px`,
              height: `${height}px`,
              borderRadius: "30px",
              backgroundColor: "#000",
              marginTop: `${marginTop}px`,
              color: "#fff",
              marginRight: 2,
              "&:hover": {
                color: "#000",
                backgroundColor: "#fff",
              },
            }
          : typeVar === OUTLINEDBLACK
          ? {
              width: `${width}px`,
              borderRadius: "30px",
              height: `${height}px`,
              color: "#000",
              marginTop: `${marginTop}px`,
              marginRight: 2,
              border: "2px solid #000",
              "&:hover": {
                border: "2px solid #000",
                backgroundColor: "#000",
                color: "#FFF",
              },
            }
          : typeVar === OUTLINEDWHITE
          ? {
              width: `${width}px`,
              borderRadius: "30px",
              height: `${height}px`,
              color: "#FFF",
              marginTop: `${marginTop}px`,
              marginRight: 2,
              border: "1px solid #FFF",
            }
          : {
              width: `${width}px`,
              borderRadius: "30px",
              height: `${height}px`,
              color: "#000",
              marginTop: `${marginTop}px`,
              marginRight: 2,
            }
      }
      startIcon={startIcon}
    >
      {isSubmitting ? <CircularIndeterminate size={25} /> : name}
    </Button>
  );
};

export default RButton;
