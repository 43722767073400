import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RButton, RCheckbox, RInput } from "src/components";
import { typeVar } from "src/components/RButton";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import pages from "src/router/routes";
import {
  getAuthToken,
  getUserData,
  resetUser,
  selectUser,
  setAuthToken,
  setUser,
} from "src/store/user/userSlice";
import { ADMIN, CAMPAIGN_MANAGER, CLIENT, SUPER_USER } from "src/types/types";
import * as Yup from "yup";
import authProvider from "../../providers/authProvider";
import customDataProvider from "src/providers/customDataProvider";
import { setSelectedClient } from "src/store/client/clientSlice";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const user = useAppSelector(selectUser);
  const authToken = useAppSelector(getAuthToken);
  useEffect(() => {
    if (user?.isVerified !== undefined && !user?.isVerified && (process.env.REACT_APP_ENV === "Production" || process.env.REACT_APP_ENV === "Staging")) {
      toast.warning('Please verify your email and password before logging in. Check your emails for the verifiction email and follow the indicated steps.')
    } else
      if (authToken) {
        let path = '';
        if (user?.userRole === CLIENT) {
          customDataProvider.getClientUser(user.id, authToken).then((response) => {
            if (response.success) {
              dispatch(setSelectedClient(response.data));
            }
          });
          path = pages.revx.currentCampaigns.path;
        }
        if (user?.userRole === SUPER_USER) path = pages.revx.client.path
        if (user?.userRole === CAMPAIGN_MANAGER) path = pages.revx.allCampaigns.path
        if (user?.userRole === ADMIN) path = pages.revx.client.path

        if (path !== '') toast.success("Login successful")
        navigate(path)
      }
  }, [user]);

  useEffect(() => {
    dispatch(resetUser());
  }, []);

  const onSubmit = (values: any) => {
    setSubmitting(true);
    authProvider
      .login(values.email, values.password)
      .then(async (response) => {
        if (response.success) {
          //route to main page
          dispatch(setAuthToken({ authToken: response.data.idToken }));
          dispatch(
            setUser({
              user: {
                id: response.data.localId,
                userRole: response.data.roleId,
              },
            })
          );
          const innerResponse = await dispatch(
            getUserData(response.data.localId)
          );

          if (innerResponse.meta.requestStatus !== "fulfilled") {
            throw Error;
          }
        } else {
          throw Error;
        }
      })
      .catch(() => {
        toast.error("Error logging in");
        setSubmitting(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        background: "#fff",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "40%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          padding: "60px",
          marginLeft: 10,
        }}
      >
        <Box sx={{ fontWeight: 700, fontSize: "40px", lineHeight: "49px" }}>
          <Typography variant="h4Bold">Hi, Welcome Back!</Typography>
        </Box>
        <Typography sx={{ marginTop: "5px", fontSize: "20px" }}>
          Let's level up your digital marketing!
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, setFieldValue, touched }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <RInput
                label="Email address"
                placeholder="Enter your email address"
                name="email"
                type="text"
              />

              <RInput
                label="Password"
                placeholder="Enter your password"
                name="password"
                type="password"
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: "18px",
                }}
              >
                <RCheckbox
                  label="Remember Me"
                  value={values.rememberMe}
                  setFieldValue={setFieldValue}
                  name={"rememberMe"}
                />
                <Typography
                  onClick={() => navigate(pages.auth.forgotPassword.path)}
                  sx={{ fontSize: "14px", marginLeft: "auto", marginTop: 1 }}
                >
                  Forgot Password?
                </Typography>
              </Box>

              <RButton
                typeVar={typeVar.Contained}
                name="Log In"
                onClick={() => {
                  setSubmitting(true)
                }}
                isSubmitting={submitting}
              />
            </Form>
          )}
        </Formik>

        <Typography sx={{ marginTop: 2, fontSize: "16px", cursor: "pointer" }}>
          Don't have an account?{" "}
          <b
            style={{ textDecoration: "underline" }}
            onClick={() => navigate(pages.auth.signup.path)}
          >
            Sign up here
          </b>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "18px",
          marginRight: "18px",
        }}
      >
        <img
          src="/static/images/app/signInImage.png"
          alt="Sign in image"
          style={{ height: "96vh" }}
        />
      </Box>
    </Box>
  );
};

export default Login;
