import { Suspense, lazy, FC } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";
import SuspenseLoader from "src/components/SuspenseLoader";
import pages from "./routes";
import Login from "src/pages/Login";
import AllCampaigns from "src/pages/AllCampaigns";
import AuthAndRoleGuard from "./AuthAndRoleGuard";
import {
  ADMIN,
  CAMPAIGN_MANAGER,
  CLIENT,
  SUPER_USER,
  VIEWER,
} from "src/types/types";
import PastCampaigns from "src/pages/PastCampaigns";
import CurrentCampaigns from "src/pages/CurrentCampaign";
import ForgotPassword from "src/pages/ForgotPassword";
import CheckEmail from "src/pages/ForgotPassword/CheckEmail";
import SetNewPassword from "src/pages/ForgotPassword/SetNewPassword";
import PasswordReset from "src/pages/ForgotPassword/PasswordReset";
import ValidateEmail from "src/pages/ValidateEmail/ValidateEmail";
import { useAppSelector } from "src/hooks/hooks";
import { selectUser } from "src/store/user/userSlice";

const Loader = (Component: FC) => (props: any) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

// Pages
const Home = Loader(lazy(() => import("src/pages/Clients")));
const RevxUsers = Loader(lazy(() => import("src/pages/RevXusers")));
const Inbox = Loader(lazy(() => import("src/pages/Inbox")));
const Profile = Loader(lazy(() => import("src/pages/Profile")));
const Signup = Loader(lazy(() => import("src/pages/Signup")));
const ClientProfile = Loader(lazy(() => import("src/pages/ClientProfile")));
const UserProfile = Loader(lazy(() => import("src/pages/ViewUserProfile")));
const ViewAllClientCampaigns = Loader(
  lazy(() => import("src/pages/ViewAllClientCampaigns"))
);
const StartANewCampaign = Loader(
  lazy(() => import("src/pages/StartANewCampaign"))
);
const FaceBookWizard = Loader(lazy(() => import("src/pages/FacebookWizard")));
const GoogleWizard = Loader(lazy(() => import("src/pages/GoogleWizard")));
const AssignCampaigns = Loader(lazy(() => import("src/pages/AssignCampaigns")));

// Status
const Status404 = Loader(
  lazy(() => import("src/components/Fallbacks/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/components/Fallbacks/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/components/Fallbacks/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/components/Fallbacks/Status/Maintenance"))
);

const CustomNavigate = () => {
  const user = useAppSelector(selectUser);

  if (user?.userRole === CLIENT)
    return <Navigate to={pages.revx.currentCampaigns.path} replace />;

  if (user?.userRole === CAMPAIGN_MANAGER)
    return <Navigate to={pages.revx.currentCampaigns.path} replace />;

  if (user?.userRole === ADMIN)
    return <Navigate to={pages.revx.client.path} replace />;

  if (user?.userRole === SUPER_USER)
    return <Navigate to={pages.revx.client.path} replace />;

  return <Navigate to={pages.revx.currentCampaigns.path} replace />;
};

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <CustomNavigate />,
      },
      {
        path: "/home",
        element: <CustomNavigate />,
      },
      {
        path: pages.status.root,
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,
            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: pages.revx.root,
        element: <SidebarLayout />,
        children: [
          {
            path: "",
            element: <Navigate to={pages.revx.client.name} replace />,
          },
          {
            path: pages.revx.client.name,
            element: (
              <AuthAndRoleGuard roles={[ADMIN, SUPER_USER]}>
                <Home />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.revxusers.name,
            element: (
              <AuthAndRoleGuard
                roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER, SUPER_USER]}
              >
                <RevxUsers />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.assignCampaigns.name,
            element: (
              <AuthAndRoleGuard
                roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER, SUPER_USER]}
              >
                <AssignCampaigns />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.clientProfile.name,
            element: (
              <AuthAndRoleGuard
                roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER, SUPER_USER]}
              >
                <ClientProfile />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.userProfile.name,
            element: (
              <AuthAndRoleGuard
                roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER, SUPER_USER]}
              >
                <UserProfile />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.viewAllClientCampaigns.name,
            element: (
              <AuthAndRoleGuard roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER]}>
                <ViewAllClientCampaigns />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.profile.name,
            element: <Profile />,
          },
          {
            path: pages.revx.allCampaigns.name,
            element: (
              <AuthAndRoleGuard roles={[CAMPAIGN_MANAGER, ADMIN, VIEWER]}>
                <AllCampaigns />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.pastCampaigns.name,
            element: (
              <AuthAndRoleGuard roles={[CLIENT]}>
                <PastCampaigns />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.currentCampaigns.name,
            element: (
              <AuthAndRoleGuard roles={[CLIENT]}>
                <CurrentCampaigns />
              </AuthAndRoleGuard>
            ),
          },
          {
            path: pages.revx.inbox.name,
            element: <Inbox />,
          },
          {
            path: pages.revx.startANewCampaign.name,
            element: <StartANewCampaign />,
          },
          {
            path: pages.revx.facebookWizard.name,
            element: <FaceBookWizard />,
          },
          {
            path: pages.revx.googleWizard.name,
            element: <GoogleWizard />,
          },
          {
            path: pages.revx.facebookWizardEdit.name,
            element: <FaceBookWizard />,
          },
          {
            path: pages.revx.googleWizardEdit.name,
            element: <GoogleWizard />,
          },
        ],
      },
      {
        path: pages.auth.root,
        children: [
          {
            path: pages.auth.login.name,
            element: <Login />,
          },
          {
            path: pages.auth.signup.name,
            element: <Signup />,
          },
          {
            path: pages.auth.forgotPassword.name,
            element: <ForgotPassword />,
          },
          {
            path: pages.auth.checkEmail.name,
            element: <CheckEmail />,
          },
          {
            path: pages.auth.validateEmail.name,
            element: <ValidateEmail />,
          },
          {
            path: pages.auth.setNewPassword.name,
            element: <SetNewPassword />,
          },
          {
            path: pages.auth.passwordReset.name,
            element: <PasswordReset />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
