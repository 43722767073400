import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };
interface RCheckBoxProps {
  label: string;
  setFieldValue: (check: string, me: boolean) => void;
  value: boolean;
  name: string;
}

const RCheckbox: React.FC<React.PropsWithChildren<RCheckBoxProps>> = ({
  label,
  setFieldValue,
  value,
  name,
}) => {
  return (
    <FormControlLabel
      control={<Checkbox sx={{"&.Mui-checked":{color:'#000'}}} />}
      label={label}
      checked={value}
      onChange={() => setFieldValue(name, !value)}
    />
  );
};

export default RCheckbox;
